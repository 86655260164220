import Page from '../../components/Page';
import { useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import * as Types from '../../types';
import { TabContentProps } from './TabNav';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Spinner,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Select,
    FormControl,
    FormLabel,
    Textarea,
    FormHelperText,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionPanel,
    AccordionIcon,
    Divider,
    Badge,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';

const RULE_ENFORCEMENT_FRAGMENT = gql`
    fragment RuleEnforcementFragment on RuleEnforcement {
        id
        graphId
        graphVariant
        policy
        params {
            key
            value
        }
        createdBy
        createdAt
        updatedAt
        deletedAt
    }
`;

const ACCOUNT_RULE_ENFORCEMENTS_QUERY = gql`
    query Account_RuleEnforcementsQuery($accountId: ID!) {
        account(id: $accountId) {
            graphs {
                id
                variants {
                    id
                    name
                    ruleEnforcements {
                        ...RuleEnforcementFragment
                    }
                }
            }
        }
    }
    ${RULE_ENFORCEMENT_FRAGMENT}
`;

const RULE_ENFORCEMENT_QUERY = gql`
    query RuleEnforcementQuery($graphId: ID!, $enforcementId: ID!) {
        graph(id: $graphId) {
            ruleEnforcement(id: $enforcementId) {
                ...RuleEnforcementFragment
            }
        }
    }
    ${RULE_ENFORCEMENT_FRAGMENT}
`;

const VARIANT_CREATE_RULE_ENFORCEMENT_MUTATION = gql`
    mutation Variant_CreateRuleEnforcementMutation($graphId: ID!, $input: CreateRuleEnforcementInput!) {
        graph(id: $graphId) {
            createRuleEnforcement(input: $input) {
                ... on RuleEnforcement {
                    ...RuleEnforcementFragment
                }
                ... on RuleEnforcementError {
                    message
                }
            }
        }
    }
    ${RULE_ENFORCEMENT_FRAGMENT}
`;

const VARIANT_UPDATE_RULE_ENFORCEMENT_MUTATION = gql`
    mutation Variant_UpdateRuleEnforcementMutation(
        $graphId: ID!
        $enforcementId: ID!
        $input: UpdateRuleEnforcementInput!
    ) {
        graph(id: $graphId) {
            updateRuleEnforcement(id: $enforcementId, input: $input) {
                ... on RuleEnforcement {
                    ...RuleEnforcementFragment
                }
                ... on RuleEnforcementError {
                    message
                }
            }
        }
    }
    ${RULE_ENFORCEMENT_FRAGMENT}
`;

const VARIANT_DELETE_RULE_ENFORCEMENT_MUTATION = gql`
    mutation Variant_DeleteRuleEnforcementMutation($graphId: ID!, $enforcementId: ID!) {
        graph(id: $graphId) {
            deleteRuleEnforcement(id: $enforcementId)
        }
    }
`;

const transformEnumPolicy = (policy: Types.EnforcementPolicy) => _.startCase(_.toLower(policy));

interface LimitsTabProps extends TabContentProps {}

export default function LimitsTab({ accountId, setActiveTab }: LimitsTabProps) {
    useEffect(() => {
        setActiveTab('limits');
    });

    const { user: me } = useIsLoggedIn();
    const { data, loading, error } = useQuery<
        Types.Account_RuleEnforcementsQuery,
        Types.Account_RuleEnforcementsQueryVariables
    >(ACCOUNT_RULE_ENFORCEMENTS_QUERY, {
        variables: { accountId },
    });

    const [showCreateRuleEnforcementModal, setShowCreateRuleEnforcementModal] = useState<boolean>(false);
    const [showUpdateRuleEnforcementModal, setShowUpdateRuleEnforcementModal] = useState<boolean>(false);
    const [selectedRuleEnforcement, setSetRuleEnforcement] = useState<Types.RuleEnforcement | undefined>();

    if (loading)
        return (
            <Page>
                <Spinner size={'lg'} />
            </Page>
        );
    if (error) return <Page>{error.message}</Page>;
    if (!data || !data.account)
        return (
            <Page>
                Account <p>{accountId}</p>'s users couldn't be found.
            </Page>
        );

    const enforcements: Types.RuleEnforcement[] = [];

    const account = data?.account;
    for (const graph of account.graphs ?? []) {
        for (const variant of graph.variants) {
            if (variant.ruleEnforcements.length > 0) {
                enforcements.push(...variant.ruleEnforcements);
            }
        }
    }

    const groupRules = groupBy(enforcements, (rule) => rule.policy);

    const graphVariantMap: GraphVariantMap = account.graphs.reduce((acc, graph) => {
        const variantNames = graph.variants.map((variant) => variant.name);
        acc[graph.id] = variantNames;
        return acc;
    }, {} as GraphVariantMap);

    const EnforcementRow = ({ enforcement }: { enforcement: Types.RuleEnforcement }) => {
        return (
            <div className="flex-1">
                <div>
                    <div className="flex space-x-2 items-center mb-1">
                        <div className="font-bold text-md">
                            {enforcement.graphId}@{enforcement.graphVariant}
                        </div>

                        {enforcement.deletedAt == null && (
                            <Badge colorScheme="green" variant={'solid'} fontSize="10px">
                                Active
                            </Badge>
                        )}
                    </div>
                    <div className="flex space-x-1">
                        <div className="text-sm text-secondary">
                            Created by {enforcement.createdBy} at{' '}
                            {new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(
                                new Date(enforcement.createdAt)
                            )}
                        </div>
                        {new Date(enforcement.createdAt).getTime() !== new Date(enforcement.updatedAt).getTime() && (
                            <div className="text-sm text-secondary italic">
                                (Last updated{' '}
                                {new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(
                                    new Date(enforcement.updatedAt)
                                )}
                                )
                            </div>
                        )}
                    </div>
                    {enforcement.deletedAt != null && (
                        <div className="text-sm text-secondary">
                            Deleted{' '}
                            {new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(
                                new Date(enforcement.deletedAt)
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const sortByDateDescending = (items: Types.RuleEnforcement[]): Types.RuleEnforcement[] =>
        items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return (
        <Page>
            <CreateRuleEnforcementModal
                accountId={accountId}
                graphVariants={graphVariantMap}
                show={showCreateRuleEnforcementModal}
                setShow={setShowCreateRuleEnforcementModal}
            />
            {selectedRuleEnforcement !== undefined && (
                <UpdateRuleEnforcementModal
                    accountId={accountId}
                    enforcement={selectedRuleEnforcement}
                    show={showUpdateRuleEnforcementModal}
                    setShow={setShowUpdateRuleEnforcementModal}
                />
            )}

            <div className="flex justify-between">
                <div>
                    <p className="text-xl font-bold text-base pb-2"> Ingestion limits</p>
                    {enforcements.length === 0 && !loading && (
                        <p className="text-secondary">There are no limits configured on this account.</p>
                    )}
                </div>

                <Button
                    type="button"
                    variant={'primary'}
                    size={'sm'}
                    onClick={() => setShowCreateRuleEnforcementModal(true)}
                    isDisabled={
                        me?.internalAdminRole !== undefined &&
                        me?.internalAdminRole !== null &&
                        ![
                            Types.InternalMdgAdminRole.INTERNAL_MDG_SUPPORT,
                            Types.InternalMdgAdminRole.INTERNAL_MDG_SUPER_ADMIN,
                        ].includes(me.internalAdminRole)
                    }
                >
                    <div className="flex items-center">
                        <PlusIcon className="mr-1 h-5 w-5" aria-hidden="true" /> Create enforcement
                    </div>
                </Button>
            </div>

            {enforcements.length > 0 && (
                <div className="mt-6 space-y-2">
                    {Object.entries(groupRules).map(([policy, enforcements]) => {
                        const activeForGroup = sortByDateDescending(
                            enforcements.filter((enf) => enf.deletedAt == null)
                        );
                        const pastForGroup = sortByDateDescending(enforcements.filter((enf) => enf.deletedAt != null));
                        const orderedForGroup = [...activeForGroup, ...pastForGroup];
                        return (
                            <Box>
                                <Accordion allowMultiple>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <div className="font-bold">
                                                {transformEnumPolicy(policy as Types.EnforcementPolicy)} (
                                                {activeForGroup.length} active)
                                            </div>
                                            <AccordionIcon />
                                        </AccordionButton>

                                        <AccordionPanel padding={0}>
                                            {orderedForGroup.map((enforcement) => (
                                                <li className="flex px-4 py-2 border-1 border-primary border-b">
                                                    <EnforcementRow enforcement={enforcement} />
                                                    {enforcement.deletedAt == null && (
                                                        <PencilIcon
                                                            className="h-4 w-4 hover:cursor-pointer"
                                                            onClick={() => {
                                                                setSetRuleEnforcement(enforcement);
                                                                setShowUpdateRuleEnforcementModal(true);
                                                            }}
                                                        />
                                                    )}
                                                </li>
                                            ))}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Box>
                        );
                    })}
                </div>
            )}
        </Page>
    );
}

type GraphVariantMap = { [key: string]: string[] };

type CreateEnforcementFormValues = {
    graph: string;
    variant: string;
    policy: Types.EnforcementPolicy;
    bloomFilter: string;
};

function CreateRuleEnforcementModal({
    accountId,
    graphVariants,
    show,
    setShow,
}: {
    accountId: string;
    graphVariants: GraphVariantMap;
    show: boolean;
    setShow: (value: boolean) => void;
}) {
    const [formValues, setFormValues] = useState<Partial<CreateEnforcementFormValues>>();
    const [error, setError] = useState<string>();

    const [createRuleEnforcement, { loading }] = useMutation<
        Types.Variant_CreateRuleEnforcementMutation,
        Types.Variant_CreateRuleEnforcementMutationVariables
    >(VARIANT_CREATE_RULE_ENFORCEMENT_MUTATION);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const isEmptyString = (value: any) => value === undefined || value === '';

    const isFormValid = () =>
        !isEmptyString(formValues?.graph) && !isEmptyString(formValues?.variant) && !isEmptyString(formValues?.policy);

    const handleSubmit = async () => {
        if (isEmptyString(formValues?.graph)) return 'Please select a Graph';
        if (isEmptyString(formValues?.variant)) return 'Please select a Variant';
        if (!formValues?.policy === undefined) return 'Please select a Policy';

        try {
            const result = await createRuleEnforcement({
                variables: {
                    graphId: formValues?.graph as string,
                    input: {
                        graphVariant: formValues?.variant,
                        policy: formValues?.policy as Types.EnforcementPolicy,
                        params:
                            formValues?.bloomFilter !== undefined
                                ? [{ key: 'bloom_filter', value: formValues.bloomFilter }]
                                : [],
                    },
                },
                refetchQueries: [{ query: ACCOUNT_RULE_ENFORCEMENTS_QUERY, variables: { accountId } }],
            });

            if (result.errors) {
                setError(result.errors.map((e) => e.message).join(', '));
                return;
            }

            const createRuleEnforcementResult = result.data?.graph?.createRuleEnforcement;
            if (createRuleEnforcementResult && createRuleEnforcementResult.__typename === 'RuleEnforcementError') {
                setError(createRuleEnforcementResult.message);
                return;
            }

            // Success creating the enforcement, close the modal.
            setError(undefined);
            setShow(false);
        } catch (e) {
            setError((e as ApolloError).message);
        }
    };

    return (
        <Modal
            isOpen={show}
            onClose={() => {
                setFormValues({});
                setShow(!show);
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <h3 className="text-center font-bold text-white">Create rule enforcement</h3>
                <ModalCloseButton />

                <ModalBody className="flex flex-col items-center rounded-lg shadow-sm space-y-2 text-center">
                    <div className="bg-secondary p-2 my-2 text-left">
                        <p className="text-xs">
                            Rule enforcements redact data during ingestion, which helps prevent cardinality explosion.
                            Data that matches active rules is combined into{' '}
                            <span className="font-mono bg-primary"># CardinalityLimitExceeded</span>, but is still
                            included in the overall usage count.
                        </p>
                        <p className="text-xs mt-4 font-bold">
                            ⚠️ This is dangerous and will impact the customer's insights data.
                        </p>
                        <p className="text-xs mt-4">
                            It should only be used during an active incident, an insights outage, or when the customer
                            has exceeded the normal operating threshold for that dimension.
                        </p>
                    </div>

                    {error && <div className="w-full bg-red-dark p-2 text-xs">{error}</div>}

                    <FormControl isRequired>
                        <FormLabel size={'lg'}>Choose a graph</FormLabel>
                        <Select name="graph" variant="flushed" placeholder="Select Graph" onChange={handleChange}>
                            {Object.keys(graphVariants).map((graph) => (
                                <option key={graph} value={graph}>
                                    {graph}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {!isEmptyString(formValues?.graph) && (
                        <FormControl isRequired className="pt-4">
                            <FormLabel>Choose a variant</FormLabel>
                            <Select
                                name="variant"
                                variant="flushed"
                                placeholder="Select Variant"
                                onChange={handleChange}
                            >
                                {graphVariants[formValues?.graph as string].map((variant) => (
                                    <option key={variant} value={variant}>
                                        {variant}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {!isEmptyString(formValues?.graph) && !isEmptyString(formValues?.variant) && (
                        <Fragment>
                            <FormControl isRequired className="pt-4">
                                <div>
                                    <FormLabel>Choose the policy to enforce</FormLabel>
                                    <FormHelperText>
                                        This policy maps to the dimension which will be redacted.
                                    </FormHelperText>
                                </div>
                                <Select
                                    name="policy"
                                    variant="flushed"
                                    placeholder="Select the policy"
                                    onChange={handleChange}
                                >
                                    {Object.values(Types.EnforcementPolicy).map((policy) => (
                                        <option key={policy} value={policy}>
                                            {transformEnumPolicy(policy)}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="w-full pt-4">
                                <FormLabel>Additional parameters (optional)</FormLabel>

                                <Accordion allowToggle className="pt-4">
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex="1" textAlign="left">
                                                    Bloom filter
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel>
                                            <FormControl>
                                                <FormHelperText textAlign={'start'} paddingBottom={4}>
                                                    To allow certain "known" good values to be ingested, you may provide
                                                    a bloom filter to the rule. To create a bloom filter reach out to
                                                    the Insights team. The bloom filter should be the base64 value that
                                                    Druid returns without any additional modifications.
                                                </FormHelperText>
                                                <Textarea
                                                    name="bloomFilter"
                                                    placeholder="Base64 bloom filter"
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>

                            <Box width={'100%'} paddingY={'10px'}>
                                <Divider />
                            </Box>

                            <Button
                                mt={4}
                                variant={'primary'}
                                isLoading={loading}
                                type="submit"
                                onClick={handleSubmit}
                                width="100%"
                                isDisabled={!isFormValid()}
                            >
                                Submit
                            </Button>
                        </Fragment>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

type UpdateEnforcementFormValues = {
    bloomFilter: string;
};

function UpdateRuleEnforcementModal({
    accountId,
    enforcement,
    show,
    setShow,
}: {
    accountId: string;
    enforcement: Types.RuleEnforcement;
    show: boolean;
    setShow: (value: boolean) => void;
}) {
    const [formValues, setFormValues] = useState<Partial<UpdateEnforcementFormValues>>();
    const [error, setError] = useState<string>();

    const {
        data,
        loading,
        error: fetchError,
    } = useQuery<Types.RuleEnforcementQuery, Types.RuleEnforcementQueryVariables>(RULE_ENFORCEMENT_QUERY, {
        variables: {
            graphId: enforcement.graphId,
            enforcementId: enforcement.id,
        },
    });

    if (fetchError) setError(fetchError.message);

    const [updateRuleEnforcement, { loading: updateLoading }] = useMutation<
        Types.Variant_UpdateRuleEnforcementMutation,
        Types.Variant_UpdateRuleEnforcementMutationVariables
    >(VARIANT_UPDATE_RULE_ENFORCEMENT_MUTATION);

    const [deleteRuleEnforcement, { loading: deleteLoading }] = useMutation<
        Types.Variant_DeleteRuleEnforcementMutation,
        Types.Variant_DeleteRuleEnforcementMutationVariables
    >(VARIANT_DELETE_RULE_ENFORCEMENT_MUTATION);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const result = await updateRuleEnforcement({
                variables: {
                    graphId: enforcement.graphId,
                    enforcementId: enforcement.id,
                    input: {
                        params:
                            formValues?.bloomFilter !== undefined
                                ? [{ key: 'bloom_filter', value: formValues.bloomFilter }]
                                : enforcementData?.params?.map((param) => ({ key: param.key, value: param.value })),
                    },
                },
                refetchQueries: [{ query: ACCOUNT_RULE_ENFORCEMENTS_QUERY, variables: { accountId: accountId } }],
            });

            if (result.errors) {
                setError(result.errors.map((e) => e.message).join(', '));
                return;
            }

            const updateRuleEnforcementResult = result.data?.graph?.updateRuleEnforcement;
            if (updateRuleEnforcementResult && updateRuleEnforcementResult.__typename === 'RuleEnforcementError') {
                setError(updateRuleEnforcementResult.message);
                return;
            }

            // Success updating the enforcement, close the modal.
            setError(undefined);
            setShow(false);
        } catch (e) {
            setError((e as ApolloError).message);
        }
    };

    const handleDelete = async () => {
        try {
            const result = await deleteRuleEnforcement({
                variables: {
                    graphId: enforcement.graphId,
                    enforcementId: enforcement.id,
                },
                refetchQueries: [{ query: ACCOUNT_RULE_ENFORCEMENTS_QUERY, variables: { accountId: accountId } }],
            });

            if (result.errors) {
                setError(result.errors.map((e) => e.message).join(', '));
                return;
            }

            if (result.data?.graph?.deleteRuleEnforcement === false) {
                setError('Unexpected error deleting this rule enforcement');
                return;
            }

            // Success deleting the enforcement, close the modal.
            setError(undefined);
            setShow(false);
        } catch (e) {
            setError((e as ApolloError).message);
        }
    };

    const enforcementData = data?.graph?.ruleEnforcement;
    const enforcementBloomFilter = enforcementData?.params?.find(({ key }) => key === 'bloom_filter')?.value;

    return (
        <Modal
            isOpen={show}
            onClose={() => {
                setFormValues({});
                setShow(!show);
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <h3 className="text-center font-bold text-white">Update rule enforcement</h3>
                <ModalCloseButton />

                <ModalBody alignContent={'center'}>
                    {error && <div className="w-full bg-red-dark p-2 text-xs">{error}</div>}

                    {loading ? (
                        <div className="text-center">
                            <Spinner marginY={8} />
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <div className="text-secondary text-center">
                                {enforcementData?.graphId}@{enforcementData?.graphVariant}
                            </div>

                            <div>
                                <div className="font-bold">Policy</div>
                                <div className="text-secondary">{transformEnumPolicy(enforcement.policy)}</div>
                            </div>

                            <div>
                                <div className="font-bold">Additional parameters (optional)</div>

                                <Accordion allowToggle className="pt-2" defaultIndex={[0]}>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex="1" textAlign="left">
                                                    Bloom filter (optional)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel>
                                            <FormControl>
                                                <FormHelperText textAlign={'start'} paddingBottom={4}>
                                                    To allow certain "known" good values to be ingested, you may provide
                                                    a bloom filter to the rule. To create a bloom filter reach out to
                                                    the Insights team. The bloom filter should be the base64 value that
                                                    Druid returns without any additional modifications.
                                                </FormHelperText>
                                                <Textarea
                                                    name="bloomFilter"
                                                    value={formValues?.bloomFilter ?? enforcementBloomFilter}
                                                    placeholder="Base64 bloom filter"
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>

                            <Button
                                mt={4}
                                variant={'primary'}
                                isLoading={updateLoading}
                                type="submit"
                                onClick={handleSubmit}
                                width="100%"
                            >
                                Submit
                            </Button>

                            <Divider />

                            <p className="text-sm text-secondary">
                                Deleting this rule enforcement will allow ingestion to resume for{' '}
                                <span className="italic">{transformEnumPolicy(enforcement.policy)}</span>. New data will
                                appear on the insights dashboard within 5 minutes.
                            </p>
                            <Button
                                mt={4}
                                variant={'destructive'}
                                isLoading={deleteLoading}
                                type="submit"
                                onClick={handleDelete}
                                width="100%"
                                size={'sm'}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
